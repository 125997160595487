var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    attrs: {
      "id": "STPG_payForm",
      "name": "STPG_payForm"
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "name": "method",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "trdDt",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "trdTm",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mchtTrdNo"
    },
    domProps: {
      "value": _vm.orderNo
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mchtName"
    },
    domProps: {
      "value": _vm.nameKo
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mchtEName"
    },
    domProps: {
      "value": _vm.nameEn
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "pmtPrdtNm"
    },
    domProps: {
      "value": _vm.orderName
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "notiUrl"
    },
    domProps: {
      "value": _vm.notiUrl
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "nextUrl"
    },
    domProps: {
      "value": _vm.resultUrl
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "cancUrl"
    },
    domProps: {
      "value": _vm.resultUrl
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainMchtCustNm"
    },
    domProps: {
      "value": _vm.buyerName
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "custAcntSumry",
      "value": "세틀뱅크"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "expireDt",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mchtParam",
      "value": "상점 예약 필드"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainCphoneNo"
    },
    domProps: {
      "value": _vm.buyerPhone
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainEmail"
    },
    domProps: {
      "value": _vm.buyerEmail
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "telecomCd",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "prdtTerm",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainMchtCustId"
    },
    domProps: {
      "value": _vm.buyerUsername
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "taxTypeCd",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainTaxAmt",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainVatAmt",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainTaxFreeAmt",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainSvcAmt",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "cardType",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "chainUserId",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "cardGb",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainClipCustNm",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainClipCustCi",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "plainClipCustPhoneNo",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "certNotiUrl",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "skipCd",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "multiPay",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "autoPayType",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "linkMethod",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "appScheme",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "custIp",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "corpPayCode",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "corpPayType",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "cashRcptUIYn",
      "value": ""
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respMchtId"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respOutStatCd"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respOutRsltCd"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respOutRsltMsg"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respMethod"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respMchtTrdNo"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respMchtCustId"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respTrdNo"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respTrdAmt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respMchtParam"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respAuthDt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respAuthNo"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respReqIssueDt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respIntMon"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respFnNm"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respFnCd"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respPointTrdNo"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respPointTrdAmt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respCardTrdAmt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respVtlAcntNo"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respExpireDt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respCphoneNo"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respBillKey"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "respCsrcAmt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "trdAmt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mchtCustNm"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "cphoneNo"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "email"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "mchtCustId"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "taxAmt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "vatAmt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "taxFreeAmt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "svcAmt"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "clipCustNm"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "clipCustCi"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "clipCustPhoneNo"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "tab"
  }, [_vm._v("UI 결제창 호출")]), _c('table', [_c('tr', [_c('td', {
    staticClass: "left"
  }, [_vm._v("상점아이디")]), _c('td', {
    staticClass: "right"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "mchtId"
    },
    domProps: {
      "value": _vm.mid
    }
  })])]), _c('tr', [_c('td', {
    staticClass: "left"
  }, [_vm._v("거래금액")]), _c('td', {
    staticClass: "right"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "plainTrdAmt"
    },
    domProps: {
      "value": _vm.paymentAmount
    }
  })])])]), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "신용카드",
      "onclick": "pay('card')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "계좌이체",
      "onclick": "pay('bank')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "가상계좌",
      "onclick": "pay('vbank')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "휴대폰(일반)",
      "onclick": "pay('mobile')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "휴대폰(자동연장 1회차)",
      "onclick": "pay('mobileAuto')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "틴캐시",
      "onclick": "pay('teencash')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "해피머니",
      "onclick": "pay('happymoney')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "문화상품권",
      "onclick": "pay('culturecash')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "스마트문상",
      "onclick": "pay('smartcash')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "도서상품권",
      "onclick": "pay('booknlife')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "티머니",
      "onclick": "pay('tmoney')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "KT클립포인트",
      "onclick": "pay('point')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "010가상계좌",
      "onclick": "pay('vbank010')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "간편결제_페이코",
      "onclick": "pay('payco')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "간편결제_카카오페이",
      "onclick": "pay('kakao')"
    }
  }), _c('input', {
    staticClass: "payBtn",
    attrs: {
      "type": "button",
      "value": "간편결제_네이버페이",
      "onclick": "pay('nvpay')"
    }
  })]);

}]

export { render, staticRenderFns }