

<template>
    <form ref="form" id="STPG_payForm" name="STPG_payForm">
        <!-- 승인 요청 파라미터(필수) -->
        <input type="hidden" name="method" value="" />                          <!-- 결제수단 -->
        <input type="hidden" name="trdDt" value="" />                           <!-- 요청일자(yyyyMMdd) -->
        <input type="hidden" name="trdTm" value="" />                           <!-- 요청시간(HHmmss)-->
        <input type="hidden" name="mchtTrdNo" :value="orderNo" />               <!-- 상점주문번호 -->
        <input type="hidden" name="mchtName" :value="nameKo" />                 <!-- 상점한글명 -->
        <input type="hidden" name="mchtEName" :value="nameEn" />                <!-- 상점영문명 -->
        <input type="hidden" name="pmtPrdtNm" :value="orderName" />             <!-- 상품명 -->
        <input type="hidden" name="notiUrl" :value="notiUrl" />                 <!-- 결과처리 URL -->
        <input type="hidden" name="nextUrl" :value="resultUrl" />               <!-- 결과화면 URL -->
        <input type="hidden" name="cancUrl" :value="resultUrl" />               <!-- 결제취소 URL -->

        <!-- 승인 요청 파라미터(옵션) -->
        <input type="hidden" name="plainMchtCustNm" :value="buyerName" />       <!-- 고객명(평문) -->
        <input type="hidden" name="custAcntSumry" value="세틀뱅크" />            <!-- 통장인자내용 -->
        <input type="hidden" name="expireDt" value="" />                        <!-- 입금만료일시(yyyyMMddHHmmss) -->
        <input type="hidden" name="mchtParam" value="상점 예약 필드" />          <!-- 상점예약필드 -->
        <input type="hidden" name="plainCphoneNo" :value="buyerPhone" />        <!-- 핸드폰번호(평문) -->
        <input type="hidden" name="plainEmail" :value="buyerEmail" />           <!-- 이메일주소(평문) -->
        <input type="hidden" name="telecomCd" value="" />                       <!-- 통신사코드 -->
        <input type="hidden" name="prdtTerm" value="" />                        <!-- 상품제공기간 -->
        <input type="hidden" name="plainMchtCustId" :value="buyerUsername" />   <!-- 상점고객아이디(평문) -->
        <input type="hidden" name="taxTypeCd" value="" />                       <!-- 면세여부(Y:면세, N:과세, G:복합과세) -->
        <input type="hidden" name="plainTaxAmt" value="" />                     <!-- 과세금액(평문)(복합과세인 경우 필수) -->
        <input type="hidden" name="plainVatAmt" value="" />                     <!-- 부가세금액(평문)(복합과세인 경우 필수) -->
        <input type="hidden" name="plainTaxFreeAmt" value="" />                 <!-- 비과세금액(평문)(복합과세인 경우 필수) -->
        <input type="hidden" name="plainSvcAmt" value="" />                     <!-- 봉사료(평문) -->
        <input type="hidden" name="cardType" value="" />                        <!-- 카드결제타입 -->
        <input type="hidden" name="chainUserId" value="" />                     <!-- 현대카드Payshot ID -->
        <input type="hidden" name="cardGb" value="" />                          <!-- 특정카드사코드 -->
        <input type="hidden" name="plainClipCustNm" value="" />                 <!-- 클립포인트고객명(평문) -->
        <input type="hidden" name="plainClipCustCi" value="" />                 <!-- 클립포인트CI(평문) -->
        <input type="hidden" name="plainClipCustPhoneNo" value="" />            <!-- 클립포인트고객핸드폰번호(평문) -->
        <input type="hidden" name="certNotiUrl" value="" />                     <!-- 인증결과URL -->
        <input type="hidden" name="skipCd" value="" />                          <!-- 스킵여부 -->
        <input type="hidden" name="multiPay" value="" />                        <!-- 포인트복합결제 -->
        <input type="hidden" name="autoPayType" value="" />                     <!-- 자동결제 타입(공백:일반결제, M:월자동 1회차) -->
        <input type="hidden" name="linkMethod" value="" />                      <!-- 연동방식 -->
        <input type="hidden" name="appScheme" value="" />                       <!-- 앱스키마 -->
        <input type="hidden" name="custIp" value="" />                          <!-- 고객IP주소 -->
        <input type="hidden" name="corpPayCode" value="" />                     <!-- 간편결제 코드 -->
        <input type="hidden" name="corpPayType" value="" />                     <!-- 간편결제 타입(CARD:카드, POINT:포인트) -->
        <input type="hidden" name="cashRcptUIYn" value="" />                    <!-- 현금영수증 발급 여부 -->

        
        <!-- 응답 파라미터 -->
        <input type="hidden" name="respMchtId" />           <!-- 상점아이디 -->
        <input type="hidden" name="respOutStatCd" />        <!-- 거래상태 -->
        <input type="hidden" name="respOutRsltCd" />        <!-- 거절코드 -->
        <input type="hidden" name="respOutRsltMsg" />       <!-- 결과메세지 -->
        <input type="hidden" name="respMethod" />           <!-- 결제수단 -->
        <input type="hidden" name="respMchtTrdNo" />        <!-- 상점주문번호 -->
        <input type="hidden" name="respMchtCustId" />       <!-- 상점고객아이디 -->
        <input type="hidden" name="respTrdNo" />            <!-- 세틀뱅크 거래번호 -->
        <input type="hidden" name="respTrdAmt" />           <!-- 거래금액 -->
        <input type="hidden" name="respMchtParam" />        <!-- 상점예약필드 -->
        <input type="hidden" name="respAuthDt" />           <!-- 승인일시 -->
        <input type="hidden" name="respAuthNo" />           <!-- 승인번호 -->
        <input type="hidden" name="respReqIssueDt" />       <!-- 채번요청일시 -->  
        <input type="hidden" name="respIntMon" />           <!-- 할부개월수 -->
        <input type="hidden" name="respFnNm" />             <!-- 카드사명 -->
        <input type="hidden" name="respFnCd" />             <!-- 카드사코드 -->
        <input type="hidden" name="respPointTrdNo" />       <!-- 포인트거래번호 -->
        <input type="hidden" name="respPointTrdAmt" />      <!-- 포인트거래금액 -->
        <input type="hidden" name="respCardTrdAmt" />       <!-- 신용카드결제금액 -->
        <input type="hidden" name="respVtlAcntNo" />        <!-- 가상계좌번호 -->
        <input type="hidden" name="respExpireDt" />         <!-- 입금기한 -->
        <input type="hidden" name="respCphoneNo" />         <!-- 휴대폰번호 -->
        <input type="hidden" name="respBillKey" />          <!-- 자동결제키 -->
        <input type="hidden" name="respCsrcAmt" />          <!-- 현금영수증 발급 금액(네이버페이) -->

        <!-- 암호화 처리 후 세팅될 파라미터-->
        <input type="hidden" name="trdAmt" />               <!-- 거래금액(암호문) -->
        <input type="hidden" name="mchtCustNm" />           <!-- 상점고객이름(암호문) -->
        <input type="hidden" name="cphoneNo" />             <!-- 휴대폰번호(암호문) -->
        <input type="hidden" name="email" />                <!-- 이메일주소(암호문) -->
        <input type="hidden" name="mchtCustId" />           <!-- 상점고객아이디(암호문) -->
        <input type="hidden" name="taxAmt" />               <!-- 과세금액(암호문) -->
        <input type="hidden" name="vatAmt" />               <!-- 부가세금액(암호문) -->
        <input type="hidden" name="taxFreeAmt" />           <!-- 면세금액(암호문) -->
        <input type="hidden" name="svcAmt" />               <!-- 봉사료(암호문) -->
        <input type="hidden" name="clipCustNm" />           <!-- 클립포인트고객명(암호문) -->
        <input type="hidden" name="clipCustCi" />           <!-- 클립포인트고객CI(암호문) -->
        <input type="hidden" name="clipCustPhoneNo" />      <!-- 클립포인트고객휴대폰번호(암호문) -->
        
        <div class="tab">UI 결제창 호출</div>
        <table>
            <tr>
                <td class="left">상점아이디</td>
                <td class="right"><input type="text" name="mchtId" :value="mid" /></td>
            </tr>
            <tr>
                <td class="left">거래금액</td>
                <td class="right"><input type="text" name="plainTrdAmt" :value="paymentAmount" /></td>
            </tr>
        </table>
        <div class="content">
            <input type="button" class="payBtn" value="신용카드" onclick="pay('card')"/>
            <input type="button" class="payBtn" value="계좌이체" onclick="pay('bank')"/> 
            <input type="button" class="payBtn" value="가상계좌" onclick="pay('vbank')"/>
            <input type="button" class="payBtn" value="휴대폰(일반)" onclick="pay('mobile')"/>
            <input type="button" class="payBtn" value="휴대폰(자동연장 1회차)" onclick="pay('mobileAuto')"/>
            <input type="button" class="payBtn" value="틴캐시" onclick="pay('teencash')"/>
            <input type="button" class="payBtn" value="해피머니" onclick="pay('happymoney')"/>
            <input type="button" class="payBtn" value="문화상품권" onclick="pay('culturecash')"/>
            <input type="button" class="payBtn" value="스마트문상" onclick="pay('smartcash')"/>
            <input type="button" class="payBtn" value="도서상품권" onclick="pay('booknlife')"/>
            <input type="button" class="payBtn" value="티머니" onclick="pay('tmoney')"/>
            <input type="button" class="payBtn" value="KT클립포인트" onclick="pay('point')"/>
            <input type="button" class="payBtn" value="010가상계좌" onclick="pay('vbank010')"/>
            <input type="button" class="payBtn" value="간편결제_페이코" onclick="pay('payco')"/>
            <input type="button" class="payBtn" value="간편결제_카카오페이" onclick="pay('kakao')"/>
            <input type="button" class="payBtn" value="간편결제_네이버페이" onclick="pay('nvpay')"/>
        </div>
    </form>
</template>

<script>
import api from "@/api";

export default {
    data(){
        return {
            mid: null,
            nameKo: null,
            nameEn: null,
            ui: this.$route.query.ui,
    
            orderNo: this.$route.query.orderNo,                   // 상품 주문번호
            orderName: this.$route.query.orderName,               // 결제상품명
            paymentAmount: +this.$route.query.paymentAmount,      // 상품 금액
            paymentMethod: this.$route.query.paymentMethod,       // 결제 방법
    
            buyerUsername: this.$route.query.buyerUsername,       // 구매자 아이디
            buyerName: this.$route.query.buyerName,               // 구매자명
            buyerPhone: this.$route.query.buyerPhone,             // 구매자 전화번호
            buyerEmail: this.$route.query.buyerEmail,             // 구매자 이메일
    
            receiverName: this.$route.query.receiverName,         // 수취인 이름
            receiverPhone: this.$route.query.receiverPhone,       // 수취인 연락처
            receiverPostcode: this.$route.query.receiverPostcode, // 배송지 우편번호
            receiverAddress1: this.$route.query.receiverAddress1, // 배송지 주소1
            receiverAddress2: this.$route.query.receiverAddress2, // 배송지 주소2
        }
    },
    computed: {
        baseUrl() {
            let { protocol, host } = window.location;
            protocol = protocol.replace(/[^a-z]/g, "");
            return `${protocol}://${host}`;
        },
        logoUrl() {
            return `${this.baseUrl}/images/logo.svg`;
        },
        returnUrl() {
            return `${this.baseUrl}/api/plugins/hecto-financial/settlebank/response`;
        },
    },
    mounted() {
        this.init();
    },
    beforeDestroy(){
        this.remove();
    },
    methods: {
        async init(){
            window.settlebankComplete = (payload) => {
                window?.parent?.settlebankComplete(payload);
                window?.opener?.settlebankComplete(payload);
            };

            let { mid, source, nameKo, nameEn } = await api.plugins.hectoFinancial.settlebank.config();

            this.mid = mid;
            this.nameKo = nameKo;
            this.nameEn = nameEn;

            const script = document.createElement('script');
            script.src = source; // 원하는 스크립트 URL을 설정하세요.
            script.id = `settlebankScript`; // 나중에 스크립트를 쉽게 제거하기 위해 id를 할당합니다.
            script.async = true;
            script.onload = this.submit;  // 스크립트 로드 완료시 실행될 콜백 함수 설정
            document.body.appendChild(script);
        },
        async submit() {
            // 헥토파이낸스 기본 설정
            this.role();

            this.$nextTick(async () => {
                var form = this.$refs.form;
                var formData = new FormData(form);
                var data = this.toJSON(formData);

                // 민감정보 암호화 및 해시값 생성
                let { gateway, trdDt, trdTm, signed, hashCipher } = await api.plugins.hectoFinancial.settlebank.generate(data);
                console.log(trdDt, trdTm)

                //가맹점 -> 세틀뱅크로 결제 요청
                window?.SETTLE_PG?.pay?.({
                    env: gateway,
                    
                    ...data,
                    ...signed,

                    trdDt,
                    trdTm,
                    prdtTerm: trdDt+trdTm,
                    
                    pktHash: hashCipher,

                    ui :{
                        type: this.ui,   //popup, iframe, self, blank
                        width: "430",   //popup창의 너비
                        height: "660"   //popup창의 높이
                    }
                }, function(rsp){   
                    //iframe인 경우 전달된 결제 완료 후 응답 파라미터 처리
                    console.log(rsp);
                });
            });
        },
        remove(){
            const script = document.getElementById('settlebankScript');
            script?.remove?.();
        },

        toJSON(formData){
            var data = {};
            for(let [key, value] of formData.entries()){
                if(Object.hasOwn(data, key)){
                    data[key] = Array.isArray(data[key]) ? [...data[key], value] : [data[key]];
                }
                else{
                    data[key] = value;
                }
            }
            return data;
        },
        role(){
            // var curr_date = new Date();
            // var year = curr_date.getFullYear().toString();
            // var month = ("0" + (curr_date.getMonth() + 1)).slice(-2).toString();
            // var day = ("0" + (curr_date.getDate())).slice(-2).toString();
            // var hours = ("0" + curr_date.getHours()).slice(-2).toString();
            // var mins = ("0" + curr_date.getMinutes()).slice(-2).toString();
            // var secs = ("0" + curr_date.getSeconds()).slice(-2).toString();
            // var random4 = ("000" + Math.random() * 10000 ).slice(-4).toString();

            // $('#STPG_payForm [name="custIp"]').val("<?php echo getRealClientIp() ?>"); //고객 IP 세팅
            // $('#STPG_payForm [name="trdDt"]').val(year + month + day);  //요청일자 세팅
            // $('#STPG_payForm [name="trdTm"]').val(hours + mins + secs); //요청시간 세팅
            // $('#STPG_payForm [name="mchtTrdNo"]').val("PAYMENT" + year + month + day + hours + mins + secs + random4);//주문번호 세팅

            // $('#STPG_payForm2 [name="trdDt"]').val(year + month + day); //요청일자 세팅
            // $('#STPG_payForm2 [name="trdTm"]').val(hours + mins + secs);//요청시간 세팅
            // $('#STPG_payForm2 [name="mchtTrdNo"]').val("AUTOPAY" + year + month + day + hours + mins + secs + random4);//주문번호 세팅

            let form = this.$refs.form;
            switch(this.paymentMethod){
                case "mobileAuto": {
                    //휴대폰 자동결제인 경우
                    form[`method`].value = "mobile";
                    form[`autoPayType`].value = "M";
                    break;
                }
                case "payco": {
                    //페이코 간편결제 결제수단 및 구분 코드 설정
                    form[`method`].value = "corp";
                    form[`corpPayCode`].value = "PAC";
                    break;
                }
                case "kakao": {
                    //카카오 간편결제 결제수단 및 구분 코드 설정
                    form[`method`].value = "corp";
                    form[`corpPayCode`].value = "KKP";
                    break;
                }
                case "nvpay": {
                    //네이버페이 간편결제 결제수단 및 구분 코드 설정
                    form[`method`].value = "corp";
                    form[`corpPayCode`].value = "NVP";
                    break;
                }
                default: {
                    form[`method`].value = this.paymentMethod;
                    break;
                }
            }
        }
    },

    computed: {
        resultUrl(){
            return `${window.location.protocol}//${window.location.host}/api/plugins/hecto-financial/settlebank/result`;
        },
        notiUrl(){
            return `${window.location.protocol}//${window.location.host}/api/plugins/hecto-financial/settlebank/result`;
        }
    }
};
</script>
